import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";

import arrowDown from "@assets/icons/arrow-down.png";
import arrowLeft from "@assets/icons/arrow-left.png";
import arrowRight from "@assets/icons/arrow-right.png";
import arrowLeftWhite from "@assets/icons/arrow-left-white.png";
import arrowRightWhite from "@assets/icons/arrow-right-white.png";
import arrowUp from "@assets/icons/arrow-up.png";
import check from "@assets/icons/check.png";
import close from "@assets/icons/close.png";
import email from "@assets/icons/email.png";
import hamburger from "@assets/icons/hamburger.png";
import minus from "@assets/icons/minus.png";
import plus from "@assets/icons/plus.png";

const ICONS_PNG = {
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowLeftWhite,
  arrowRightWhite,
  arrowUp,
  check,
  close,
  email,
  hamburger,
  minus,
  plus,
  prev: arrowLeft,
  next: arrowRight,
};

const instagram = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" class="onhover-white" clip-rule="evenodd" d="M12 7.50833C13.4667 7.50833 13.65 7.50833 14.2458 7.55417C15.7583 7.6 16.4458 8.33333 16.4917 9.8C16.5375 10.3958 16.5375 10.5333 16.5375 12C16.5375 13.4667 16.5375 13.65 16.4917 14.2C16.4458 15.6667 15.7125 16.4 14.2458 16.4458C13.65 16.4917 13.5125 16.4917 12 16.4917C10.5333 16.4917 10.35 16.4917 9.8 16.4458C8.2875 16.4 7.6 15.6667 7.55417 14.2C7.50833 13.6042 7.50833 13.4667 7.50833 12C7.50833 10.5333 7.50833 10.35 7.55417 9.8C7.6 8.33333 8.33333 7.6 9.8 7.55417C10.35 7.50833 10.5333 7.50833 12 7.50833ZM9.15833 12C9.15833 10.4417 10.4417 9.15833 12 9.15833C13.5583 9.15833 14.8417 10.4417 14.8417 12C14.8417 13.5583 13.5583 14.8417 12 14.8417C10.4417 14.8417 9.15833 13.5583 9.15833 12ZM14.2917 9.06667C14.2917 8.7 14.5667 8.425 14.9333 8.425C15.3 8.425 15.575 8.7 15.575 9.06667C15.575 9.43333 15.3 9.70833 14.9333 9.70833C14.5667 9.70833 14.2917 9.43333 14.2917 9.06667Z" fill="#333333"/>
<path class="onhover-white" d="M12 13.8333C10.9917 13.8333 10.1667 13.0083 10.1667 12C10.1667 10.9917 10.9917 10.1667 12 10.1667C13.0083 10.1667 13.8333 10.9917 13.8333 12C13.8333 13.0083 13.0083 13.8333 12 13.8333Z" fill="#333333"/>
<rect x="1" y="1" width="22" height="22" stroke="#333333" stroke-width="2"/>
</svg>
`;

const linkedin = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="onhover-white" d="M9.08333 8.25C9.08333 8.83333 8.625 9.29166 8.04167 9.29166C7.45833 9.29166 7 8.83333 7 8.25C7 7.66666 7.45833 7.20833 8.04167 7.20833C8.625 7.20833 9.08333 7.66666 9.08333 8.25ZM9.08333 10.125H7V16.7917H9.08333V10.125ZM12.4167 10.125H10.3333V16.7917H12.4167V13.2917C12.4167 11.3333 14.9167 11.1667 14.9167 13.2917V16.7917H17V12.5833C17 9.29166 13.2917 9.41666 12.4167 11.0417V10.125Z" fill="#333333"/>
<rect x="1" y="1" width="22" height="22" stroke="#333333" stroke-width="2"/>
</svg>
`;

const twitter = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1449 16.0636C13.9187 16.0636 15.9824 12.9371 15.9824 10.2261C15.9824 10.1373 15.9806 10.0489 15.9765 9.96088C16.3771 9.67131 16.7253 9.30986 17 8.89859C16.6324 9.06192 16.2368 9.1719 15.8218 9.22149C16.2454 8.96752 16.5706 8.56557 16.7241 8.08652C16.3276 8.32161 15.8886 8.49244 15.4213 8.58457C15.0468 8.18581 14.5136 7.93639 13.9237 7.93639C12.7905 7.93639 11.8716 8.85526 11.8716 9.98784C11.8716 10.1489 11.8897 10.3054 11.9248 10.4556C10.2197 10.37 8.70774 9.55348 7.69595 8.31217C7.51955 8.61528 7.41798 8.96763 7.41798 9.3433C7.41798 10.0551 7.78023 10.6834 8.33105 11.0512C7.99451 11.0408 7.67832 10.9482 7.40183 10.7945C7.40137 10.803 7.40137 10.8116 7.40137 10.8206C7.40137 11.8143 8.1087 12.6439 9.04736 12.8319C8.87505 12.8789 8.69375 12.9041 8.50655 12.9041C8.37438 12.9041 8.24586 12.8909 8.12087 12.8672C8.382 13.6822 9.13949 14.2755 10.0375 14.2922C9.33523 14.8425 8.45059 15.1704 7.48941 15.1704C7.32392 15.1704 7.1606 15.161 7 15.142C7.90795 15.7239 8.98606 16.0636 10.1449 16.0636Z" fill="#333333"/>
<rect x="1" y="1" width="22" height="22" stroke="#333333" stroke-width="2"/>
</svg>`;

const facebook = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="onhover-white" d="M10.75 10.3333H9.5V12H10.75V17H12.8333V12H14.3333L14.5 10.3333H12.8333V9.625C12.8333 9.25 12.9167 9.08333 13.2917 9.08333H14.5V7H12.9167C11.4167 7 10.75 7.66667 10.75 8.91667V10.3333Z" fill="#333333"/>
<rect x="1" y="1" width="22" height="22" stroke="#333333" stroke-width="2"/>
</svg>
`;

const arrow = `<svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 6L0.271636 0V12L11 6Z" fill="#333333"/>
</svg>
`;

const location = `<svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Location</title><path d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="256" cy="192" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>`;

const SIZES = {
  xxs: {
    mobile: 8,
    desktop: 8,
  },
  xs: {
    mobile: 16,
    desktop: 16,
  },
  sm: {
    mobile: 24,
    desktop: 24,
  },
  md: {
    mobile: 24,
    desktop: 24,
  },
  lg: {
    mobile: 32,
    desktop: 48,
  },
};

export const IconPng = ({ name, size }) => {
  const iconUrl = ICONS_PNG[name];
  return (
    <img
      src={iconUrl}
      css={css`   
        min-width: 24px;
      `}
      alt="Icon"
    />
  );
};

const Icon = ({ name = 'heart', size = 'md', color }) => {
  // const circleSize = `${1.85 * SIZES[size]}px`;
  const icons = { facebook, linkedin, instagram, location, arrow, twitter };

  return (
    <div className="flex items-center justify-center "
      css={css`
        &:hover {
          svg {
            background: #333;
            .onhover-white {
              fill: white;
            }
            .onhover-black {
              fill: black;
            }
          }
        }

        svg {
          width: ${SIZES[size].mobile}px;
          height: ${SIZES[size].mobile}px;
          min-width: ${SIZES[size].mobile}px;

          path {
            fill: ${color || "#333"};
          }
        }

        @media (min-width: 768px) {
          svg {
            width: ${SIZES[size].desktop}px;
            height: ${SIZES[size].desktop}px;
            min-width: ${SIZES[size].desktop}px;
          }
        }
      `}
      dangerouslySetInnerHTML={{ __html: icons[name] }}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
