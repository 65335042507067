import { css } from "@emotion/react";
import React from "react";
import ogni from "@assets/ogni.png";
import voepe from "@assets/voepe.png";
import zt from "@assets/zt.png";

const FooterLink = ({ imgUrl, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    css={css`
      margin: 0px 0px 0px 18px;
      max-width: 45px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;

      @media (min-width: 992px) {
        margin-left: 18px;
      }
    `}
  >
    <img src={imgUrl} alt="memebership" />
  </a>
);

const MembershipLinks = () => {
  const links = [
    {
      url: "https://www.ogni.at",
      imgUrl: ogni,
    },
    {
      url: "https://www.voepe.at/",
      imgUrl: voepe
    },
    {
      url: "https://wien.arching.at/home.html",
      imgUrl: zt
    },
  ];
  return (
    <React.Fragment>
      <div className="flex">
        {links.map((link, index) => (
          <FooterLink key={`link--${index}`} {...link} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default MembershipLinks;
